import React, { useState } from 'react'
import useInterval from 'react-useinterval'

import './styles.scss'

interface IBeerGuy {
  number: string
  visible: boolean
  screens?: number
  screensLeft?: number
}

const BeerGuy = () => {
  const [beerGuyArr, setBeerGuyArr] = useState<IBeerGuy[]>([
    { number: '1', visible: true, screens: 15 },
    { number: '2', visible: false },
    { number: '3', visible: false },
    { number: '4', visible: false },
    { number: '5', visible: false },
    { number: '6', visible: false, screens: 10 },
    { number: '5', visible: false },
    { number: '4', visible: false },
    { number: '3', visible: false },
    { number: '2', visible: false }
  ])

  useInterval(() => {
    const newBeerGuyArr = [...beerGuyArr]
    for (let i = 0; i < beerGuyArr.length; ++i) {
      const beerGuy = beerGuyArr[i]
      if (beerGuy.visible) {
        const screensLeft: number = beerGuy.screensLeft || beerGuy.screens || 0
        if (screensLeft > 1) beerGuy.screensLeft = screensLeft - 1
        else {
          beerGuy.visible = false
          const nextBeerGuy = newBeerGuyArr[i + 1] || newBeerGuyArr[0]
          nextBeerGuy.visible = true
          if (nextBeerGuy.screens) nextBeerGuy.screensLeft = nextBeerGuy.screens
        }
        break
      }
    }
    setBeerGuyArr(newBeerGuyArr)
  }, 100)

  return (
    <div className='beer-guy-wrapper'>
      <div className='beer-guy'>
        {beerGuyArr.map((beerGuy, i) => (
          <img
            key={i}
            className='beer-guy-img'
            src={`/content/scene2/beer-guy/${beerGuy.number}.png`}
            style={{ display: beerGuy.visible ? 'block' : 'none' }}
          />
        ))}
      </div>
    </div>
  )
}

export default BeerGuy
