import React, { useRef, useEffect } from 'react'

import useAppState from '../../services/appState'

import './styles.scss'

const Glitters = () => {
  const el = useRef<HTMLVideoElement>(null)

  const sceneSwitched = useAppState(state => state.sceneSwitched)

  useEffect(() => {
    if (sceneSwitched) {
      setTimeout(() => { el.current?.play() }, window.outerWidth < 600 ? 500 : 1000)
    }
  }, [sceneSwitched])

  return (
    <div className='glitters'>
      <video
        src={`/content/scene1/glitters${window.outerWidth < 600 ? '-s' : ''}.mp4`}
        ref={el}
        controls={false}
        playsInline={true}
      />
    </div>
  )
}

export default Glitters
