import React from 'react'

import Cat from '../cat'

import './styles.scss'

const Race = () => {
  return (
    <div className='race-wrapper'>
      <div className='race'>
        <img className='mouse1' src='/content/scene2/mice/1.png' />
        <img className='mouse2' src='/content/scene2/mice/2.png' />
        <Cat />
      </div>
    </div>
  )
}

export default Race
