const contentUrls: string[] = [
  '/content/scene2/beer-guy/1.png',
  '/content/scene2/beer-guy/2.png',
  '/content/scene2/beer-guy/3.png',
  '/content/scene2/beer-guy/4.png',
  '/content/scene2/beer-guy/5.png',
  '/content/scene2/beer-guy/6.png',
  '/content/scene2/cat/1.png',
  '/content/scene2/cat/2.png',
  '/content/scene2/cat/3.png',
  '/content/scene2/clouds/clouds.png',
  '/content/scene2/diamond/diamond.png',
  '/content/scene2/diamond/diamond-shine.png',
  '/content/scene2/lady/1.png',
  '/content/scene2/lady/2.png',
  '/content/scene2/lady/3.png',
  '/content/scene2/mice/1.png',
  '/content/scene2/mice/2.png',
  '/content/scene2/trash/can.png',
  '/content/scene2/trash/can2.png',
  '/content/scene2/trash/paper.png',
  '/content/scene2/trash/paper-he.png',
  '/content/scene2/tv/tv.png',
  '/content/scene2/wall/paper.png',
  '/content/scene2/wall/paper-he.png',
  '/content/scene2/wall/peeper.png',
  '/content/scene2/wall/wall.png',
  '/content/scene2/waving-man/waving-man.png',
  '/content/scene2/waving-man/waving-hand.png',
  '/content/scene2/wheel/1.png',
  '/content/scene2/wheel/2.png',
  '/content/scene2/wheel/3.png',
  '/content/scene2/wheel/4.png',
  '/content/scene2/beer-pile.png',
  '/content/scene2/ground.png',
  '/content/scene2/pile-left.png',
  '/content/scene2/pile-left2.png',
  '/content/scene2/reasons2.png',
  '/content/scene2/sky.png',
  '/content/scene2/tv-pile-skeleton.png',
  '/content/scene2/tv-pile.png'
]

const preloadFile = (url: string) => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.src = url
    img.onload = () => { resolve(true) }
  })
}

const preload = () => {
  return Promise.all(contentUrls.map(preloadFile))
}

export default preload
