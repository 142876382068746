import React, { useState, useEffect } from 'react'
import useInterval from 'react-useinterval'

import useAppState from '../../services/appState'

import './styles.scss'

interface ITrash {
  number: string
  progress: number
  progressInit: number
}

interface ICat {
  number: string
  visible: boolean
}

interface ISunset {
  number: string
  visible: boolean
}

const SceneOne = () => {
  const isPreloaderVisible = useAppState(state => state.isPreloaderVisible)
  const sceneSwitched = useAppState(state => state.sceneSwitched)
  const setSceneSwitched = useAppState(state => state.setSceneSwitched)

  const [sceneProgress, setSceneProgress] = useState(0)

  useInterval(() => {
    const newSceneProgress = sceneProgress + 0.3
    if (newSceneProgress < 100) setSceneProgress(newSceneProgress)
    else setSceneProgress(0)
  }, 50)

  const [trashArr, setTrashArr] = useState<ITrash[]>([
    { number: '3', progressInit: -75, progress: -75 },
    { number: '2', progressInit: -50, progress: -50 },
    { number: '1', progressInit: -25, progress: -25 },
    { number: '4', progressInit: 0, progress: 0 },
    { number: '3', progressInit: 25, progress: 25 },
    { number: '2', progressInit: 50, progress: 50 },
    { number: '1', progressInit: 75, progress: 75 }
  ])

  const [catArr, setCatArr] = useState<ICat[]>([
    { number: '1', visible: true },
    { number: '2', visible: false },
    { number: '3', visible: false },
    { number: '4', visible: false },
    { number: '5', visible: false }
  ])

  const [sunsetArr, setSunsetArr] = useState<ISunset[]>([
    { number: '1', visible: true },
    { number: '2', visible: false },
    { number: '3', visible: false }
  ])

  const [ufoStyles, setUfoStyles] = useState<React.CSSProperties>({})

  useEffect(() => {
    if (!sceneSwitched) {
      setTrashArr(trashArr.map(trash => ({
        ...trash,
        progress: trash.progressInit + sceneProgress
      })))
      if (sceneProgress >= 20 && sceneProgress <= 60) {
        setUfoStyles({
          display: 'block',
          transform: `translate(${30 * (sceneProgress - 20)}px, ${-45 * (sceneProgress - 20)}px)`
        })
      } else setUfoStyles({})
    }
  }, [sceneProgress])

  useInterval(() => {
    if (sceneSwitched) {
      setCatArr([
        { number: '1', visible: false },
        { number: '2', visible: false },
        { number: '3', visible: true },
        { number: '4', visible: false },
        { number: '5', visible: false }
      ])
    } else {
      const newCatArr = [...catArr]
      for (let i = 0; i < catArr.length; ++i) {
        if (catArr[i].visible) {
          newCatArr[i].visible = false
          if (newCatArr[i + 1]) newCatArr[i + 1].visible = true
          else newCatArr[0].visible = true
          break
        }
      }
      setCatArr(newCatArr)
    }
  }, 200)

  useInterval(() => {
    if (!sceneSwitched) {
      const newSunsetArr = [...sunsetArr]
      for (let i = 0; i < sunsetArr.length; ++i) {
        if (sunsetArr[i].visible) {
          newSunsetArr[i].visible = false
          if (newSunsetArr[i + 1]) newSunsetArr[i + 1].visible = true
          else newSunsetArr[0].visible = true
          break
        }
      }
      setSunsetArr(newSunsetArr)
    }
  }, 333)

  return (
    <div className='scene1-wrapper'>
      <div className='scene1'>
        <img className='reasons-img' src='/content/scene1/btns/reasons.png' />
        <div className='sunset-wrapper'>
          {sunsetArr.map((sunset, i) => (
            <img
              key={i}
              className='sunset'
              style={{
                backgroundImage: `url('/content/scene1/sunset/${sunset.number}.png')`,
                display: sunset.visible ? 'block' : 'none'
              }}
            />
          ))}
        </div>
        <img className='ufo' src='/content/scene1/ufo.png' style={ufoStyles}/>
        <div className='ground' style={{ backgroundImage: 'url(\'content/scene1/ground.png\')' }}/>
        {trashArr.map((trash, i) => {
          const style: React.CSSProperties = {}
          const { progress } = trash
          if (progress >= 0 && progress <= 105) {
            style.display = 'block'
            if (progress >= 20 && progress < 105) {
              style.zIndex = 11
              style.transform = `scale(${0.35 + (progress - 20) * 0.02})`
            }
            if (progress < 20) {
              style.bottom = `${progress * 0.5 + 30}%`
            }
            if (progress >= 20 && progress < 45) {
              style.bottom = `${40 - (progress - 20) * 0.5}%`
            }
            if (progress >= 45 && progress < 105) {
              style.bottom = `${28 - (progress - 44) * 1}%`
            }
            if (progress >= 85 && progress < 105) {
              style.opacity = `${1 - (progress - 85) / 20}`
            }
          }
          return <img
            className='trash'
            key={i}
            src={`content/scene1/trash/${trash.number}${window.outerWidth < 600 ? '-s' : ''}.png`}
            data-trash={trash.number}
            style={style}
          />
        })}
        <div className='cat'>
          {catArr.map((cat, i) => (
            <img
              key={i}
              className='cat-img'
              src={`/content/scene1/cat/${cat.number}.png`}
              style={{ display: cat.visible ? 'block' : 'none' }}
            />
          ))}
          <div className='cat-balls-wrapper' onClick={() => { setSceneSwitched(true) }}>
            <div className='cat-balls'>
              <div className='cat-balls-shadow' />
                {catArr.map((cat, i) => (
                  <img
                    key={i}
                    src={`/content/scene1/cat/b${cat.number}.png`}
                    style={{ display: cat.visible ? 'block' : 'none' }}
                  />
                ))}
              </div>
          </div>
        </div>
      </div>
      { isPreloaderVisible &&
        <div className='prerender'>
          <img src={`content/scene1/trash/1${window.outerWidth < 600 ? '-s' : ''}.png`} />
          <img src={`content/scene1/trash/2${window.outerWidth < 600 ? '-s' : ''}.png`} />
          <img src={`content/scene1/trash/3${window.outerWidth < 600 ? '-s' : ''}.png`} />
          <img src={`content/scene1/trash/4${window.outerWidth < 600 ? '-s' : ''}.png`} />
        </div>
      }
    </div>
  )
}

export default SceneOne
