import React from 'react'

import TV from './tv'
import WavingMan from './waving-man'
import Diamond from './diamond'
import Wheel from './wheel'
import Lady from './lady'
import BeerGuy from './beer-guy'
import Wall from './wall'
import Trash from './trash'
import Clouds from './clouds'

import './styles.scss'
import './media-styles-desktop.scss'
import './media-styles-mobile.scss'

const SceneTwo = () => {
  return (
    <div className='scene2-wrapper'>
      <div className='scene2'>
        <img className='reasons-img' src='/content/scene2/reasons2.png' />
        <div className='sky' style={{ backgroundImage: 'url("/content/scene2/sky.png")' }}></div>
        <Clouds />
        <Wheel />
        <div className='ground' style={{ backgroundImage: 'url("/content/scene2/ground.png")' }}></div>
        <WavingMan />
        <div className='tv-pile-wrapper'>
          <div className='tv-pile'>
            <img className='tv-pile-img' src='/content/scene2/tv-pile.png'/>
            <TV />
            <img className='tv-skeleton-img' src='/content/scene2/tv-pile-skeleton.png'/>
          </div>
        </div>
        <div className='beer-pile-wrapper'>
          <div className='beer-pile'>
            <img className='beer-pile-img' src='/content/scene2/beer-pile.png'/>
            <BeerGuy />
          </div>
        </div>
        <img className='pile-left2-img' src='/content/scene2/pile-left2.png' />
        <Trash />
        <Wall />
        <div className='pile-left-wrapper'>
          <div className='pile-left'>
            <img className='pile-left-img' src='/content/scene2/pile-left.png' />
            <Diamond />
          </div>
        </div>
        <img className='pile-right' src='/content/scene2/pile-right.png' />
        <Lady />
      </div>
    </div>
  )
}

export default SceneTwo
