import React, { useState } from 'react'
import useInterval from 'react-useinterval'

import './styles.scss'

interface ILady {
  number: string
  visible: boolean
  screens?: number
  screensLeft?: number
}

const Lady = () => {
  const [ladyArr, setLadyArr] = useState<ILady[]>([
    { number: '1', visible: true, screens: 15 },
    { number: '2', visible: false },
    { number: '3', visible: false, screens: 15 },
    { number: '2', visible: false }
  ])

  useInterval(() => {
    const newLadyArr = [...ladyArr]
    for (let i = 0; i < ladyArr.length; ++i) {
      const lady = ladyArr[i]
      if (lady.visible) {
        const screensLeft: number = lady.screensLeft || lady.screens || 0
        if (screensLeft > 1) lady.screensLeft = screensLeft - 1
        else {
          lady.visible = false
          const nextLady = newLadyArr[i + 1] || newLadyArr[0]
          nextLady.visible = true
          if (nextLady.screens) nextLady.screensLeft = nextLady.screens
        }
        break
      }
    }
    setLadyArr(newLadyArr)
  }, 100)

  return (
    <div className='lady-wrapper'>
      <div className='lady'>
        {ladyArr.map((lady, i) => (
          <img
            key={i}
            className='lady-img'
            src={`/content/scene2/lady/${lady.number}.png`}
            style={{ display: lady.visible ? 'block' : 'none' }}
          />
        ))}
      </div>
    </div>
  )
}

export default Lady
