import React from 'react'

import './styles.scss'

const Diamond = () => {
  return (
    <div className='diamond-wrapper'>
      <div className='diamond'>
        <img className='diamond-img' src='/content/scene2/diamond/diamond.png' />
        <img className='diamond-shine-img' src='/content/scene2/diamond/diamond-shine.png' />
      </div>
    </div>
  )
}

export default Diamond
