import React, { useState } from 'react'
import useInterval from 'react-useinterval'

import './styles.scss'

interface IWheel {
  number: string
  visible: boolean
  screens?: number
  screensLeft?: number
}

const Wheel = () => {
  const [wheelArr, setWheelArr] = useState<IWheel[]>([
    { number: '1', visible: true },
    { number: '2', visible: false },
    { number: '3', visible: false },
    { number: '4', visible: false }
  ])

  useInterval(() => {
    const newWheelArr = [...wheelArr]
    for (let i = 0; i < wheelArr.length; ++i) {
      const wheel = wheelArr[i]
      if (wheel.visible) {
        const screensLeft: number = wheel.screensLeft || wheel.screens || 0
        if (screensLeft > 1) wheel.screensLeft = screensLeft - 1
        else {
          wheel.visible = false
          const nextWheel = newWheelArr[i + 1] || newWheelArr[0]
          nextWheel.visible = true
          if (nextWheel.screens) nextWheel.screensLeft = nextWheel.screens
        }
        break
      }
    }
    setWheelArr(newWheelArr)
  }, 500)

  return (
    <div className='wheel-wrapper'>
      <div className='wheel'>
        {wheelArr.map((wheel, i) => (
          <img
            key={i}
            className='wheel-img'
            src={`/content/scene2/wheel/${wheel.number}.png`}
            style={{ display: wheel.visible ? 'block' : 'none' }}
          />
        ))}
      </div>
    </div>
  )
}

export default Wheel
