import React, { useRef, useEffect } from 'react'

import useAppState from '../../../services/appState'

import './styles.scss'

const TV = () => {
  const el = useRef<HTMLVideoElement>(null)

  const sceneSwitched = useAppState(state => state.sceneSwitched)

  useEffect(() => {
    setTimeout(() => {
      if (sceneSwitched && el.current) el.current.play()
    }, 500)
  }, [sceneSwitched])

  return (
    <div className='tv-wrapper'>
      <div className='tv'>
        <video src={`/content/scene2/tv/tv-content${window.outerWidth < 600 ? '-s' : ''}.mp4`} ref={el} controls={false} loop={true} playsInline={true} />
        <img src='/content/scene2/tv/tv.png' />
      </div>
    </div>
  )
}

export default TV
