import React, { useState, useEffect } from 'react'
import classNames from 'classnames'

import Preloader from '../preloader'
import SceneOne from '../scene-one'
import Glitters from '../glitters'
import SceneTwo from '../scene-two'

import useAppState from '../../services/appState'
import preload from '../../services/preloader'

import './styles.scss'

const App = () => {
  const isPreloaderVisible = useAppState(state => state.isPreloaderVisible)
  const setIsStartBtnVisible = useAppState(state => state.setIsStartBtnVisible)
  const sceneSwitched = useAppState(state => state.sceneSwitched)
  const [sceneOneVisible, setSceneOneVisible] = useState(true)
  const [glittersVisible, setGlittersVisible] = useState(true)

  useEffect(() => {
    preload().then(() => {
      setIsStartBtnVisible(true)
    })
  }, [])

  useEffect(() => {
    if (sceneSwitched) {
      setTimeout(() => { setSceneOneVisible(false) }, 2000)
      setTimeout(() => { setGlittersVisible(false) }, 5000)
    }
  }, [sceneSwitched])

  return (
    <div className={classNames('app-root', {
      'scene-switched': sceneSwitched
    })}>
      { isPreloaderVisible && <Preloader /> }
      { sceneOneVisible && <SceneOne /> }
      { glittersVisible && <Glitters /> }
      <SceneTwo />
    </div>
  )
}

export default App
