import React, { useState } from 'react'
import useInterval from 'react-useinterval'

import './styles.scss'

interface ICat {
  number: string
  visible: boolean
  screens?: number
  screensLeft?: number
}

const Cat = () => {
  const [catArr, setCatArr] = useState<ICat[]>([
    { number: '1', visible: true },
    { number: '2', visible: false },
    { number: '1', visible: false },
    { number: '3', visible: false }
  ])

  useInterval(() => {
    const newCatArr = [...catArr]
    for (let i = 0; i < catArr.length; ++i) {
      const cat = catArr[i]
      if (cat.visible) {
        const screensLeft: number = cat.screensLeft || cat.screens || 0
        if (screensLeft > 1) cat.screensLeft = screensLeft - 1
        else {
          cat.visible = false
          const nextCat = newCatArr[i + 1] || newCatArr[0]
          nextCat.visible = true
          if (nextCat.screens) nextCat.screensLeft = nextCat.screens
        }
        break
      }
    }
    setCatArr(newCatArr)
  }, 150)

  return (
    <div className='cat-wrapper'>
      <div className='cat'>
        {catArr.map((cat, i) => (
          <img
            key={i}
            className='cat-img'
            src={`/content/scene2/cat/${cat.number}.png`}
            style={{ display: cat.visible ? 'block' : 'none' }}
          />
        ))}
      </div>
    </div>
  )
}

export default Cat
