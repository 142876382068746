import React from 'react'

import useAppState from '../../services/appState'

import './styles.scss'

const Preloader = () => {
  const isStartBtnVisible = useAppState(state => state.isStartBtnVisible)
  const setIsPreloaderVisible = useAppState(state => state.setIsPreloaderVisible)
  return (
    <div className='preloader-wrapper'>
      <div className='preloader'>
        <img className='preloader-img' src='/content/scene1/preloader.png' />
        <img
        className='preloader-start'
        src='/content/scene1/btns/start.png'
        style={isStartBtnVisible ? { display: 'block', opacity: 1 } : {}}
        onClick={() => {
          setIsPreloaderVisible(false)
        }} />
      </div>
    </div>
  )
}

export default Preloader
