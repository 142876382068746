import React from 'react'

import './styles.scss'

const Trash = () => {
  return (
    <div className='trash-wrapper'>
      <div className='trash'>
        <img className='trash-img' src='/content/scene2/trash/can.png' />
        <img className='trash-img-sm' src='/content/scene2/trash/can2.png' />
        <img className='paper-img' src='/content/scene2/trash/paper.png' />
        <img className='paper-img paper-img-he' src='/content/scene2/trash/paper-he.png' />
      </div>
    </div>
  )
}

export default Trash
