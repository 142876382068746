import React from 'react'
import Race from '../race'

import './styles.scss'

const Wall = () => {
  return (
    <div className='wall-wrapper'>
      <div className='wall'>
        <Race />
        <img className='peeper-img' src='/content/scene2/wall/peeper.png' />
        <img className='wall-img' src='/content/scene2/wall/wall.png' />
        <img className='paper-img' src='/content/scene2/wall/paper.png' />
        <img className='paper-img paper-img-he' src='/content/scene2/wall/paper-he.png' />
      </div>
    </div>
  )
}

export default Wall
