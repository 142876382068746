import React from 'react'

import './styles.scss'

const WavingMan = () => {
  return (
    <div className='waving-man-wrapper'>
      <div className='waving-man'>
        <img className='waving-hand-img' src='/content/scene2/waving-man/waving-hand.png' />
        <img className='waving-man-img' src='/content/scene2/waving-man/waving-man.png' />
      </div>
    </div>
  )
}

export default WavingMan
