import create from 'zustand'

interface IAppState {
  isPreloaderVisible: boolean
  setIsPreloaderVisible: (newValue: boolean) => void
  isStartBtnVisible: boolean
  setIsStartBtnVisible: (newValue: boolean) => void
  sceneSwitched: boolean
  setSceneSwitched: (newValue: boolean) => void
}

const useAppState = create<IAppState>(set => ({
  isPreloaderVisible: true,
  setIsPreloaderVisible: (newValue: boolean) => set({ isPreloaderVisible: newValue }),
  isStartBtnVisible: false,
  setIsStartBtnVisible: (newValue: boolean) => set({ isStartBtnVisible: newValue }),
  sceneSwitched: false,
  setSceneSwitched: (newValue: boolean) => set({ sceneSwitched: newValue })
}))

export default useAppState
