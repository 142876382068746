import React from 'react'

import './styles.scss'

const Clouds = () => {
  return (
    <div className='clouds-wrapper'>
      <div className='clouds'>
        <img className='clouds-img' src='/content/scene2/clouds/clouds.png' />
        <img className='clouds-img' src='/content/scene2/clouds/clouds.png' />
      </div>
    </div>
  )
}

export default Clouds
